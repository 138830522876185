import type { Writable } from 'svelte/store';
import { get, writable } from 'svelte/store';
import { set_toggle, set_rem } from 'common/gloomhaven';
import { Hex, add_hex } from 'common/hex';
import { AbilityCardStatus } from 'common/info';
import type { Ability } from 'common/stats';
import { AbilityFlag } from 'common/stats';
import type { HashMap } from 'common/utility';
import type { NotificationSettings } from 'common/client';
import { local_get, local_set } from 'v2/util';

export const SETTINGS_VERSION = 1;

export let draw_audio: any = null;

export const audio = [
  [null, 'None'],
  ['/public/audio/relaxing-bell-ding-sound.mp3', 'Bell 1'],
  ['/public/audio/single-ding-sound-effect.mp3', 'Bell 2'],
  ['/public/audio/timpani-single-hit-sound-effect.mp3', 'Drum 1'],
  ['/public/audio/short-timpani-battle-accent.mp3', 'Drum 2'],
  ['/public/audio/soft-gong-sound-effect.mp3', 'Gong'],
  ['/public/audio/horn-sound.mp3', 'Horn'],
];

export interface ScreenSection {
  content: string;
  style: string;
}

export interface ScreenLayout {
  columns: number;
  widths: number[];
  grid: string[][];
}

export function default_screen_layouts(): HashMap<ScreenLayout> {
  return {
    A: {
      columns: 4,
      widths: [0, 0, 100, 0],
      grid: [
        ['pages', 'header', 'header', 'header'],
        ['pages', 'map', 'current', 'log'],
        ['pages', 'map', 'footer', 'footer'],
      ],
    },
    B: {
      columns: 4,
      widths: [0, 50, 50, 0],
      grid: [
        ['pages', 'header', 'header', 'header'],
        ['pages', 'map', 'current', 'log'],
        ['pages', 'map', 'footer', 'footer'],
      ],
    },
    C: {
      columns: 4,
      widths: [0, 45, 40, 15],
      grid: [
        ['pages', 'header', 'header', 'header'],
        ['pages', 'map', 'current', 'log'],
        ['pages', 'map', 'footer', 'footer'],
      ],
    },
    D: {
      columns: 4,
      widths: [0, 40, 60, 0],
      grid: [
        ['pages', 'header', 'header', 'header'],
        ['pages', 'tracker', 'current', 'log'],
        ['pages', 'tracker', 'footer', 'footer'],
      ],
    },
    E: {
      columns: 4,
      widths: [20, 30, 40, 10],
      grid: [
        ['pages', 'header', 'header', 'header'],
        ['pages', 'map', 'tracker', 'log'],
        ['pages', 'map', 'footer', 'footer'],
      ],
    },
  };
}

export const default_notification_settings: NotificationSettings = {
  enabled_uuids: [],
  my_turn: false,
  monster_turns: false,
  other_turns: false,
  event_cards: false,
  while_connected: false,
};

export interface UiSettings {
  modifier_codes: string[];
  ability_render: string;
  cards_enabled: boolean;
  show_selected_abilities: boolean;
  show_battle_goal: boolean;
  use_actions: boolean;
  column_layout: boolean;
  smaller_keypad: boolean;
  portraits_advance: boolean;
  advance_by_round: boolean;
  modifier_decks_advanced: boolean;
  ability_decks_advanced: boolean;
  show_ability_deck: boolean;
  auto_center: boolean;
  hide_approximate_init: boolean;
  show_damage: boolean;
  screen_layouts: HashMap<ScreenLayout>;
  selected_layouts: HashMap<string>;
  zoom_pcts: HashMap<number>;
  longpress_timeout: number;
  fh_supply_edit_all: boolean;
  no_invert_standees: boolean;
  condensed_abilities: boolean;
  ability_label: string;
  draw_audio_url: string;
  hide_figure_details: boolean;
  nav_bar_full_screen: boolean;
  show_bulk_interface: boolean;
  show_all_classes: boolean;
  show_forteller: boolean;
}

export const default_ui_settings: UiSettings = {
  modifier_codes: ['monster'],
  ability_render: 'Computed',
  cards_enabled: false,
  show_selected_abilities: false,
  show_battle_goal: true,
  use_actions: true,
  column_layout: false,
  smaller_keypad: false,
  portraits_advance: false,
  advance_by_round: false,
  modifier_decks_advanced: false,
  ability_decks_advanced: false,
  show_ability_deck: false,
  auto_center: false,
  hide_approximate_init: false,
  show_damage: false,
  screen_layouts: default_screen_layouts(),
  selected_layouts: {},
  zoom_pcts: {},
  longpress_timeout: 500,
  fh_supply_edit_all: false,
  no_invert_standees: false,
  condensed_abilities: false,
  ability_label: 'Name',
  draw_audio_url: null,
  hide_figure_details: null,
  nav_bar_full_screen: false,
  show_bulk_interface: false,
  show_all_classes: false,
  show_forteller: false,
};

export interface MovableHex {
  q: number;
  r: number;
  id: number;
}

export interface MapSettings {
  move_lines: string;
  hide_figures: boolean;
  hide_tokens: boolean;
  hide_overlays: boolean;
  hide_overlay_icons: boolean;
  show_tile_borders: boolean;
  hide_names: boolean;
  show_figure_names: boolean;
  hide_unrevealed: boolean;
  hide_tiles: boolean;
  hide_coordinates: boolean;
  show_move_coords: boolean;
  show_jotl: boolean;
  show_selected_abilities: boolean;
  moving_id: number;
  selected_hex: Hex;
  hide_edit_figures: false;
  hide_footer: boolean;
  edit_map: boolean;
  auto_center: boolean;
  show_damage: boolean;
  enable_move_assist: boolean;
  show_group_order: boolean;
  show_tile_names: boolean;
  edit_monsters: boolean;
  hide_context_abilities: boolean;
  hide_figure_details: boolean;
  show_context_bulk: boolean;
}

const default_map_settings: MapSettings = {
  move_lines: 'selected',
  hide_figures: false,
  hide_tokens: false,
  hide_overlays: false,
  hide_overlay_icons: false,
  show_tile_borders: true,
  hide_names: false,
  show_figure_names: true,
  hide_unrevealed: true,
  hide_tiles: false,
  hide_coordinates: false,
  show_move_coords: false,
  show_jotl: false,
  show_selected_abilities: false,
  moving_id: 0,
  selected_hex: null,
  hide_edit_figures: false,
  hide_footer: false,
  edit_map: false,
  auto_center: false,
  show_damage: false,
  enable_move_assist: false,
  show_group_order: false,
  show_tile_names: false,
  edit_monsters: false,
  hide_context_abilities: false,
  hide_figure_details: false,
  show_context_bulk: false,
};

export interface TableCell {
  column: number;
  row: number;
}

export interface TableCard {
  info_id: number;
  info_kind: number;
  column: number;
  row: number;
}

export interface Table {
  key: string;
  grid: TableCard[][];
  max_row: number;
  max_column: number;
  version: number;
}

export interface TableSettings {
  show_removed: boolean;
  move_by_default: boolean;
  manual_sort: boolean;
  show_empty_rows: boolean;
  edit_layout: boolean;
  row_layout: number[][];
  hide_split_headers: boolean;
}

export const table_version = 2;

export const default_row_layout: number[][] = [
  [AbilityCardStatus.MISC],
  [AbilityCardStatus.ITEM],
  [AbilityCardStatus.ACTIVE_PERSISTENT, AbilityCardStatus.ACTIVE_ROUND],
  [AbilityCardStatus.SELECT, AbilityCardStatus.HAND, AbilityCardStatus.DISCARD],
  [AbilityCardStatus.SELECT, AbilityCardStatus.HAND, AbilityCardStatus.DISCARD],
  [AbilityCardStatus.DISCARD],
  [AbilityCardStatus.LOST],
  [AbilityCardStatus.SUPPLY],
  [AbilityCardStatus.REMOVE],
];

const default_table_settings: TableSettings = {
  show_removed: true,
  move_by_default: false,
  manual_sort: false,
  show_empty_rows: true,
  edit_layout: false,
  row_layout: default_row_layout,
  hide_split_headers: false,
};

export interface ScenarioPagesSettings {
  hide_unrevealed_sections: boolean;
  reduce_brightness: boolean;
  hide_split_headers: boolean;
}

const default_scenario_pages_settings: ScenarioPagesSettings = {
  hide_unrevealed_sections: true,
  reduce_brightness: false,
  hide_split_headers: false,
};

export interface MoveAssistSettings {
  ai_rules_id: number;
  disable_los: boolean;
  disable_map_fov: boolean;
  fov_range: number;
  debug_count: number;
}

interface AllSettings {
  settings_version: number;
  ui_settings: UiSettings;
  map_settings: MapSettings;
  scenario_pages_settings: ScenarioPagesSettings;
  move_assist_settings: MoveAssistSettings;
  table_settings: TableSettings;
}

function settings_changed() {
  local_set('settings_changed', true);
}

export function export_settings(): AllSettings {
  const sent: number = local_get('settings_sent', -1);
  const changed: boolean = local_get('settings_changed', false);
  if (sent === SETTINGS_VERSION && !changed) return null;
  local_set('settings_changed', false);
  local_set('settings_sent', SETTINGS_VERSION);
  return {
    settings_version: SETTINGS_VERSION,
    ui_settings: load_one('ui_settings', default_ui_settings),
    map_settings: load_one('map_settings', default_map_settings),
    scenario_pages_settings: load_one(
      'scenario_pages_settings',
      default_scenario_pages_settings
    ),
    table_settings: load_one('table_settings', default_table_settings),
    move_assist_settings: load_one('move_assist_settings', default_move_assist_settings),
  };
}

export function export_notifications(): NotificationSettings {
  return load_one('notification_settings', default_notification_settings);
}

const default_move_assist_settings: MoveAssistSettings = {
  ai_rules_id: 0,
  disable_los: false,
  disable_map_fov: false,
  fov_range: 4,
  debug_count: 1,
};

export interface DebugSettings {
  debug_los: boolean;
  los_index: number;
}

const default_debug_settings: DebugSettings = {
  debug_los: false,
  los_index: 0,
};

export interface CardsUiState {
  player_id: number;
  selected_card: TableCard;
  is_moving: boolean;
  maximize: boolean;
}

export const default_cards_ui_state: CardsUiState = {
  player_id: 0,
  selected_card: null,
  is_moving: false,
  maximize: false,
};

export const cards_ui_state: Writable<CardsUiState> = writable(
  local_get('cards_ui_state', default_cards_ui_state)
);

export const notification_settings: Writable<NotificationSettings> = writable(
  load_one('notification_settings', default_notification_settings)
);

export const ui_settings: Writable<UiSettings> = writable(
  init_ui_settings('ui_settings', default_ui_settings)
);

export const map_settings: Writable<MapSettings> = writable(
  load_one('map_settings', default_map_settings)
);

export const table_settings: Writable<TableSettings> = writable(
  load_one('table_settings', default_table_settings)
);

export const is_moving_card: Writable<boolean> = writable(false);

export const scenario_pages_settings: Writable<ScenarioPagesSettings> = writable(
  load_one('scenario_pages_settings', default_scenario_pages_settings)
);

export const move_assist_settings: Writable<MoveAssistSettings> = writable(
  load_one('move_assist_settings', default_move_assist_settings)
);

export const show_card_buttons: Writable<boolean> = writable(
  local_get('show_card_buttons', true)
);

export function toggle_show_card_buttons() {
  show_card_buttons.update((old) => {
    local_set('show_card_buttons', !old);
    return !old;
  });
}

export const show_bulk_buttons: Writable<boolean> = writable(
  local_get('show_bulk_buttons2', false)
);

export function toggle_bulk_buttons() {
  show_bulk_buttons.update((old) => {
    local_set('show_bulk_buttons2', !old);
    return !old;
  });
}

export interface MapAoe {
  ability: Ability;
  hex: Hex;
  flip: boolean;
  rotate: number;
}

export const map_aoe: Writable<MapAoe> = writable(load_one('map_aoe', null));

export function clear_map_aoe() {
  map_aoe.update(() => null);
}

export function set_map_aoe(ability: Ability, hex: Hex) {
  map_aoe.update((old) =>
    old ? null : { ability, hex: Hex(hex.q, hex.r), rotate: 0, flip: false }
  );
}

export function move_map_aoe(hex: Hex) {
  map_aoe.update((old) => old && { ...old, hex: Hex(hex.q, hex.r) });
}

export function delta_map_aoe(q: number, r: number) {
  map_aoe.update((old) => old && { ...old, hex: add_hex(old.hex, Hex(q, r)) });
}

export function rotate_map_aoe(delta: number) {
  map_aoe.update((old) => old && { ...old, rotate: (old.rotate + delta) % 6 });
}

export function flip_map_aoe() {
  map_aoe.update((old) => old && { ...old, flip: !old.flip });
}

// Use the global enable_debug to tell the app to collect the client side debug
// data. A page might need to reload to get generate it.
export let enable_debug = local_get('enable_debug', false);
export function toggle_debug() {
  enable_debug = !enable_debug;
  local_set('enable_debug', enable_debug);
}

export const debug_settings: Writable<DebugSettings> = writable(
  load_one('debug_settings', default_debug_settings)
);

function init_ui_settings(key: string, settings: any) {
  const result = load_one(key, settings);
  if (!draw_audio && result.draw_audio_url) draw_audio = new Audio(result.draw_audio_url);
  return result;
}

function load_one(key: string, settings: any) {
  const dup = JSON.parse(JSON.stringify(settings));
  const new_settings = local_get(key, null);
  if (!new_settings) return dup;
  return { ...dup, ...new_settings };
}

function update_one<T>(store: Writable<T>, key: string, settings: T) {
  if (!settings) return;
  const dup = JSON.parse(JSON.stringify(settings));
  local_set(key, dup);
  store.update(() => dup);
}

export function reset_settings() {
  update_one(ui_settings, 'ui_settings', default_ui_settings);
  update_one(map_settings, 'map_settings', default_map_settings);
  update_one(table_settings, 'table_settings', default_table_settings);
  update_one(
    scenario_pages_settings,
    'scenario_pages_settings',
    default_scenario_pages_settings
  );
  update_one(move_assist_settings, 'move_assist_settings', default_move_assist_settings);
  is_moving_card.update(() => false);
  settings_changed();
}

export function update_settings(new_settings: any) {
  update_one(ui_settings, 'ui_settings', {
    ...default_ui_settings,
    ...new_settings.ui_settings,
  });
  update_one(map_settings, 'map_settings', {
    ...default_map_settings,
    ...new_settings.map_settings,
  });
  update_one(table_settings, 'table_settings', {
    ...default_table_settings,
    ...new_settings.table_settings,
  });
  update_one(scenario_pages_settings, 'scenario_pages_settings', {
    ...default_scenario_pages_settings,
    ...new_settings.scenario_pages_settings,
  });
  update_one(move_assist_settings, 'move_assist_settings', {
    ...default_move_assist_settings,
    ...new_settings.move_assist_settings,
  });
  is_moving_card.update(() => false);
  settings_changed();
}

export function toggle_notification(field: keyof NotificationSettings) {
  notification_settings.update((old) => {
    const updated = { ...old, [field]: !old[field] };
    local_set('notification_settings', updated);
    return updated;
  });
}

export function set_notification(field: keyof NotificationSettings, value: any) {
  settings_changed();
  notification_settings.update((old) => {
    const updated = { ...old, [field]: value };
    local_set('notification_settings', updated);
    return updated;
  });
}

export function toggle_ui_field(field: keyof UiSettings) {
  settings_changed();
  ui_settings.update((old) => {
    const updated = { ...old, [field]: !old[field] };
    local_set('ui_settings', updated);
    return updated;
  });
}

export function set_ui_field(field: keyof UiSettings, value: any) {
  settings_changed();
  ui_settings.update((old) => {
    const updated = { ...old, [field]: value };
    local_set('ui_settings', updated);
    return updated;
  });
}

export function toggle_map_field(field: keyof MapSettings) {
  settings_changed();
  map_settings.update((old) => {
    const updated: MapSettings = {
      ...old,
      selected_hex: null,
      moving_id: 0,
      [field]: !old[field],
    };
    local_set('map_settings', updated);
    return updated;
  });
}

export function set_map_field(field: keyof MapSettings, value: any) {
  if (!['selected_hex', 'moving_id'].includes(field)) settings_changed();
  map_settings.update((old) => {
    const updated: MapSettings = {
      ...old,
      selected_hex: null,
      moving_id: 0,
      [field]: value,
    };
    local_set('map_settings', updated);
    return updated;
  });
}

export function clear_selected_hex() {
  map_settings.update((old) => {
    old.selected_hex = null;
    old.moving_id = 0;
    local_set('map_settings', old);
    return { ...old };
  });
}

export function toggle_table_field(field: keyof TableSettings) {
  settings_changed();
  table_settings.update((old) => {
    const updated = { ...old, [field]: !old[field] };
    local_set('table_settings', updated);
    return updated;
  });
}

export function update_layout(content: string, layout: string) {
  settings_changed();
  ui_settings.update((old) => {
    if (!old.selected_layouts) old.selected_layouts = {};
    old.selected_layouts[content] = ['A', 'B', 'C', 'D', 'E'].includes(layout)
      ? layout
      : 'A';
    local_set('ui_settings', old);
    return { ...old };
  });
}

export function update_zoom(content: string, value: number, is_set?: boolean) {
  settings_changed();
  ui_settings.update((old) => {
    if (!old.zoom_pcts) old.zoom_pcts = {};
    const zoom_pct = old.zoom_pcts[content] || 100;
    old.zoom_pcts[content] = Math.max(
      10,
      Math.min(is_set ? value : zoom_pct + value, 300)
    );
    local_set('ui_settings', old);
    return { ...old };
  });
}

export function table_update_layout(row: number, selected: number[]) {
  settings_changed();
  table_settings.update((old) => {
    if (!old.row_layout) old.row_layout = default_row_layout;
    old.row_layout = old.row_layout.slice(0);
    old.row_layout[row] = selected.slice(0);
    local_set('table_settings', old);
    return { ...old };
  });
}

export function table_set_row_layout(row_layout: number[][]) {
  settings_changed();
  table_settings.update((old) => {
    old.row_layout = JSON.parse(JSON.stringify(row_layout));
    local_set('table_settings', old);
    return { ...old };
  });
}

export function clear_modifier(code: string) {
  settings_changed();
  ui_settings.update((old) => {
    const new_set = { ...old };
    set_rem(new_set.modifier_codes, code);
    local_set('ui_settings', new_set);
    return new_set;
  });
}

export function toggle_modifier(code: string, checked: boolean) {
  settings_changed();
  ui_settings.update((old) => {
    const new_set = { ...old };
    set_toggle(new_set.modifier_codes, code);
    local_set('ui_settings', new_set);
    return new_set;
  });
}

export function toggle_fullscreen() {
  if (document.fullscreenElement) exit_fullscreen();
  else request_fullscreen();
}

export function invoke_fullscreen() {
  if (!document.fullscreenElement) request_fullscreen();
}

export function request_fullscreen() {
  const elem: any = document.documentElement;
  if (elem.requestFullscreen) {
    elem.requestFullscreen();
  } else if (elem.webkitRequestFullscreen) {
    /* Safari */
    elem.webkitRequestFullscreen();
  } else if (elem.msRequestFullscreen) {
    /* IE11 */
    elem.msRequestFullscreen();
  }
}

export function exit_fullscreen() {
  const elem: any = document;
  if (elem.exitFullscreen) {
    elem.exitFullscreen();
  } else if (elem.webkitExitFullscreen) {
    /* Safari */
    elem.webkitExitFullscreen();
  } else if (elem.msExitFullscreen) {
    /* IE11 */
    elem.msExitFullscreen();
  }
}

export function toggle_scenario_pages_field(field: keyof ScenarioPagesSettings) {
  settings_changed();
  scenario_pages_settings.update((old) => {
    const updated = { ...old, [field]: !old[field] };
    local_set('scenario_pages_settings', updated);
    return updated;
  });
}

export function set_cards_fields(value: any) {
  settings_changed();
  cards_ui_state.update((old) => {
    const updated = { ...old, ...value };
    local_set('cards_ui_state', updated);
    return updated;
  });
}

export function set_cards_field(field: keyof CardsUiState, value: any) {
  settings_changed();
  cards_ui_state.update((old) => {
    const updated = { ...old, [field]: value };
    if (old.player_id !== updated.player_id) {
      updated.selected_card = null;
      updated.is_moving = false;
    }
    local_set('cards_ui_state', updated);
    return updated;
  });
}

export function toggle_cards_field(field: keyof CardsUiState) {
  settings_changed();
  cards_ui_state.update((old) => {
    const updated = { ...old, [field]: !old[field] };
    local_set('cards_ui_state', updated);
    return updated;
  });
}

export function set_move_assist_field(field: keyof MoveAssistSettings, value: any) {
  settings_changed();
  move_assist_settings.update((old) => {
    const updated = { ...old, [field]: value };
    local_set('move_assist_settings', updated);
    return updated;
  });
}

export function toggle_move_assist_field(field: keyof MoveAssistSettings) {
  settings_changed();
  move_assist_settings.update((old) => {
    const updated = { ...old, [field]: !old[field] };
    local_set('move_assist_settings', updated);
    return updated;
  });
}

export function inc_move_assist_field(field: keyof MoveAssistSettings, delta: any) {
  settings_changed();
  move_assist_settings.update((old) => {
    const updated = { ...old, [field]: old[field] + delta };
    local_set('move_assist_settings', updated);
    return updated;
  });
}

export function set_debug_field(field: keyof DebugSettings, value: any) {
  debug_settings.update((old) => {
    const updated = { ...old, [field]: value };
    local_set('debug_settings', updated);
    return updated;
  });
}

export function toggle_debug_field(field: keyof DebugSettings) {
  debug_settings.update((old) => {
    const updated = { ...old, [field]: !old[field] };
    local_set('debug_settings', updated);
    return updated;
  });
}

export function set_draw_audio(event: any) {
  const url = event.detail.selected;
  set_ui_field('draw_audio_url', url);
  draw_audio = url ? new Audio(url) : null;
  play_draw_audio('set audio');
}

export function play_draw_audio(msg: string) {
  if (draw_audio) {
    try {
      console.log('audio', msg);
      draw_audio.play()?.catch((e: any) => console.error(e));
    } catch (e) {
      console.error(e);
    }
  }
}
