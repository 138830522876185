import type { ComponentKind } from './components.js';
import type { MapItem } from './info.js';

export enum CommandKind {
  NONE = 0,
  RESET = 1, // handle specially to reset the world.
  UNDO = 2,
  REDO = 3,
  USER_UNDO = 4,
  USER_REDO = 5,
  START_SCENARIO = 6,
  ADD_MAP_ITEM = 7,
  ADD_CHARACTER = 8,
  ADD_MONSTER = 9,
  ADD_BOSS = 10,
  ADD_OBJECTIVE = 11,
  ADD_SUMMON = 12,
}

export interface Command {
  command_kind: CommandKind;
}

export interface ScenarioId extends Command {
  scenario_id: number;
}

export interface InfoId extends Command {
  info_id: number;
}

export interface InfoMapItem extends Command {
  map_item: MapItem;
}

export interface ValueDelta {
  command_kind: CommandKind;
  entity_id: number;
  component_kind: ComponentKind;
  delta: number;
}
