import type { HashMap } from './utility.js';
import { deep_freeze } from './utility.js';
import * as Cmd from '../common/commands.js';
import * as Comp from '../common/components.js';
import type { Component } from './components.js';
import { ComponentKind } from './components.js';
import type { Command } from './commands.js';
import { CommandKind } from './commands.js';

export type { Component, Command };
export { ComponentKind, CommandKind };

export interface Entity {
  entity_id: number;
  components: HashMap<Component>;
}

// Transaction requirements.
// - Print logs based on transactions only. i.e., be able to replay them from tx 0.
// - allow reviewing without actual undo
// - TODO: limit undos to original owner only
export interface Transaction {
  user_index: number;
  commands: Command[];
  undo_edits: EditEntity[];
  redo_edits: EditEntity[];
}

// Assuming a Component is small, this saves whole components, before and after.
// TODO: before and after could be an array of indexes to a shared pool of
// components which might reduce the overall memory needed.
export interface EditEntity {
  entity_id: number;
  components: HashMap<Component>;
}

export interface WorldSync {
  uuid: string;
  user_names_length: number;
  transactions_length: number;
  user_names?: string[];
  transactions?: Transaction[];
}

// Never expose access to the "entities" array. This implementations uses
// freeze and a transaction API to ensure all changes are tracked.
export class World {
  uuid: string;
  user_names: string[];
  transactions: Transaction[];
  entities: Entity[];

  // Construct from data read from storage.
  constructor(world?: World) {
    if (world) {
      this.uuid = world.uuid;
      this.user_names = world.user_names;
      this.entities = world.entities;
      this.transactions = world.transactions;
    } else {
      this.reset('');
    }
    this.entities.filter(Boolean).forEach(deep_freeze);
  }

  reset(uuid: string) {
    this.uuid = uuid;
    this.user_names = [];
    // Initialize all of the preset entities to some default.
    this.entities = [
      {
        entity_id: 0,
        components: {
          [ComponentKind.CURRENT_TRANSACTION_ID]: {
            transaction_id: 0,
          } satisfies Comp.TransactionId,
        },
      },
      {
        entity_id: 1,
        components: {
          [ComponentKind.SCENARIO]: {
            campaign_name: 'GHFS',
            game_id: 1,
            scenario_id: -1,
            name: 'Manual',
            icon: null,
            player_count: 2,
            difficulty: 0,
            scenario_level: 1,
            monster_level: 1,
            gold_per_token: 1,
            bonus_xp: 1,
            trap_damage: 1,
            hazard_damage: 1,
          } satisfies Comp.Scenario,
        },
      },
      {
        entity_id: 2,
        components: {
          [ComponentKind.ROUND]: { play_state: 0, round: 1 } satisfies Comp.Round,
        },
      },
      {
        entity_id: 3,
        components: {
          [ComponentKind.ELEMENTS]: {
            fire: 0,
            ice: 0,
            air: 0,
            earth: 0,
            light: 0,
            dark: 0,
          } satisfies Comp.Elements,
        },
      },
      {
        entity_id: 4,
        components: {
          [ComponentKind.REVEALED]: { revealed: [] } satisfies Comp.Revealed,
        },
      },
    ];
    this.transactions = [
      {
        user_index: -1,
        commands: [],
        undo_edits: [],
        redo_edits: [],
      },
    ];
  }

  user_index(user_name: string): number {
    if (!user_name) return -1;
    const index = this.user_names.findIndex((n) => n === user_name);
    if (index >= 0) return index;
    this.user_names.push(user_name);
    return this.user_names.length - 1;
  }

  entity(entity_id: number): Entity {
    return this.entities[entity_id];
  }

  new_entity_id() {
    return this.entities.length;
  }

  component<T extends Component>(entity_id: number, component_kind: ComponentKind): T {
    return this.entities[entity_id]?.components?.[component_kind] as T;
  }

  transaction(transaction_id: number): Transaction {
    return this.transactions[transaction_id];
  }

  current_transaction_id(): number {
    return (
      this.component<Comp.TransactionId>(0, ComponentKind.CURRENT_TRANSACTION_ID)
        ?.transaction_id ?? -1
    );
  }

  next_transaction_id(): number {
    return (
      this.component<Comp.TransactionId>(0, ComponentKind.NEXT_TRANSACTION_ID)
        ?.transaction_id ?? -1
    );
  }

  undo_transaction_id(): number {
    return (
      this.component<Comp.TransactionId>(0, ComponentKind.UNDO_TRANSACTION_ID)
        ?.transaction_id ?? -1
    );
  }

  new_transaction_id(): number {
    return this.transactions.length;
  }

  // Apply and push a new transaction. On the server, undo_edits is created
  // new. On a client, undo_edits already exists, but this will recreate it.
  // Transactions should be atomic.
  // TODO: only send redo_edits to the clients.
  // TODO: enforce the atomic requirement.
  add_transaction(new_tx: Transaction): number[] {
    // UNDO and REDO are special, we use the undo_edits and redo_edits
    // from the original transactions, then apply any new edits.
    const changed_ids = new Set<number>(new_tx.redo_edits.map((e) => e.entity_id));
    if (new_tx.commands[0].command_kind === CommandKind.UNDO) {
      // apply edits from that transaction
      const current_tx_id = this.current_transaction_id();
      const tx = this.transaction(current_tx_id);
      if (current_tx_id <= 0 || !tx) return;
      tx.undo_edits.forEach((edit) => changed_ids.add(this.apply_edit(edit).entity_id));
    } else if (new_tx.commands[0].command_kind === CommandKind.REDO) {
      // apply edits from that transaction and the undo transaction
      const next_tx = this.transaction(this.next_transaction_id());
      const undo_tx = this.transaction(this.undo_transaction_id());
      if (!next_tx || !undo_tx) return;
      next_tx.redo_edits.forEach((edit) =>
        changed_ids.add(this.apply_edit(edit).entity_id)
      );
      undo_tx.undo_edits.forEach((edit) =>
        changed_ids.add(this.apply_edit(edit).entity_id)
      );
    } else {
      // When adding a non-UNDO/REDO, make sure the next and undo ids are null.
      this.apply_edit({
        entity_id: 0,
        components: {
          [ComponentKind.NEXT_TRANSACTION_ID]: null,
          [ComponentKind.UNDO_TRANSACTION_ID]: null,
        },
      });
    }
    new_tx.undo_edits = new_tx.redo_edits.map((edit) => this.apply_edit(edit)).reverse();
    this.transactions.push(deep_freeze(new_tx));
    return Array.from(changed_ids);
  }

  private apply_edit(edit: EditEntity): EditEntity {
    let new_components: HashMap<Component> = null;
    if (edit.components === null) {
      new_components = JSON.parse(
        JSON.stringify(this.entities[edit.entity_id].components)
      );
      // delete the entity
      this.entities[edit.entity_id] = null;
    } else if (!this.entities[edit.entity_id]) {
      new_components = null;
      // add a new entity
      this.entities[edit.entity_id] = deep_freeze({
        entity_id: edit.entity_id,
        components: edit.components,
      });
    } else {
      // change the components
      new_components = {};
      const entity = JSON.parse(JSON.stringify(this.entities[edit.entity_id]));
      Object.entries(edit.components).forEach((entry) => {
        const component_kind: ComponentKind = Number(entry[0]);
        const component: Component = entry[1];
        new_components[component_kind] = entity.components[component_kind] ?? null;
        if (!component) {
          delete entity.components[component_kind];
        } else {
          entity.components[component_kind] = component;
        }
      });
      this.entities[edit.entity_id] = deep_freeze(entity);
    }
    return { entity_id: edit.entity_id, components: new_components };
  }

  client_send_sync(): WorldSync {
    return {
      uuid: this.uuid,
      user_names_length: this.user_names.length,
      transactions_length: this.transactions.length,
    };
  }

  client_send_command(command: Command): any {
    // the data that is expected by "gh:s:play3"
    return { command, sync: this.client_send_sync() };
  }

  // TODO: generate error when things don't match.
  client_receive_sync(sync: WorldSync): number[] {
    if (
      this.user_names.length !== sync.user_names_length ||
      this.transactions.length !== sync.transactions_length
    )
      return null;
    sync.user_names.forEach((name: string) => this.user_names.push(name));
    const changed_ids = new Set(
      sync.transactions.flatMap((tx: Transaction) => this.add_transaction(tx))
    );
    return Array.from(changed_ids);
  }

  server_send_message(sync: WorldSync) {
    if (sync.uuid === this.uuid)
      return {
        sync: {
          ...sync,
          user_names:
            this.user_names.length > sync.user_names_length
              ? this.user_names.slice(sync.user_names_length - this.user_names.length)
              : [],
          transactions:
            this.transactions.length > sync.transactions_length
              ? this.transactions.slice(
                  sync.transactions_length - this.transactions.length
                )
              : [],
        },
      };
    return { play3: this };
  }

  toString(): string {
    return (
      `Users:${JSON.stringify(this.user_names)}\n` +
      'Entities:\n' +
      this.entities
        .filter(Boolean)
        .map((e) => toStringEntity(e) + '\n')
        .join('') +
      'Transactions:\n' +
      this.transactions
        .map(
          (t, i) =>
            `${i}:${JSON.stringify(t.commands)}\n` +
            '  undo:\n' +
            (t.undo_edits ?? []).map((e) => `  ${toStringEntity(e)}\n`).join('') +
            '  redo:\n' +
            (t.redo_edits ?? []).map((e) => `  ${toStringEntity(e)}\n`).join('')
        )
        .join('')
    );
  }
}

function toStringEntity(entity: Entity): string {
  return (
    String(entity.entity_id) +
    ':' +
    (!entity.components
      ? 'null'
      : Object.entries(entity.components)
          .map((e) => e[0] + ':' + JSON.stringify(e[1]))
          .join(','))
  );
}
