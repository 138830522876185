import type { FsImage } from 'common/fs_image';
import type { MapState, Settings } from 'common/gloomhaven';
import { zero_fh_cost } from 'common/gloomhaven';
import type { BookInfo, LabelRect, Rect, ScenarioPageInfo } from 'common/info';
import { AbilityCardStatus } from 'common/info';
import type {
  AbilityCardDetail,
  BrowseAbilityCardDetail,
  BrowseMobDetail,
  BrowseScenarioDetail,
  BrowseSimpleDetail,
  ClassDetail,
  FullClassDetail,
  FullScenarioDetail,
  ItemsPage,
  JournalEntry,
  LootDeckDetail,
  MilestoneDetail,
  PlayCardDetail,
  PlayPlayerDetail,
  PlayerDetail,
  RenderPageData,
  ScenarioDetail,
  ScenarioPagesPage,
  ScenarioTabsPage,
  SpecialRulesDetail,
} from 'common/render';

const default_fs_image: FsImage = {
  layout: 0,
  value: '',
};

const default_scenario_detail: ScenarioDetail = {
  game_id: 0,
  scenario_id: 0,
  code: '',
  name: '',
  region: '',
  status: '',
  image: default_fs_image,
  requirements: '',
  goal: '',
};

const default_map_state: MapState = {
  revealed_tiles: [],
  tiles: [],
  doors: [],
  start: [],
  overlays: [],
  tokens: [],
  monsters: [],
  room_card_ids: [],
  monster_card_ids: [],
};

const default_full_scenario_detail: FullScenarioDetail = {
  game_id: 0,
  scenario_id: 0,
  status: '',
  code: '',
  name: '',
  region: '',
  coordinate: '',
  goal: '',
  requirements: '',
  links: '',
  treasures: [],
  traps: [],
  hazards: [],
  conclusion: [],
  completed_journal_id: 0,
  has_book: false,
};

const default_special_rules_detail: SpecialRulesDetail = {
  start_of_scenario: [],
  start_of_round: [],
  end_of_round: [],
  info: [],
};

const default_loot_deck_detail: LootDeckDetail = {
  players: [],
  remaining: 0,
  counts: [],
  actions_1418: [],
  actions_1419: [],
};

const default_milestone_detail: MilestoneDetail = {
  name: '',
  image: default_fs_image,
  checkmarks: 0,
  unlocked: false,
};

const default_class_detail: ClassDetail = {
  game_id: 0,
  class_id: 0,
  code: '',
  name: '',
};

const default_player_detail: PlayerDetail = {
  ...default_class_detail,
  player_id: 0,
  status: '',
  gold: 0,
  xp: 0,
  level: 0,
  resources: [],
  use_crossover: false,
  present: true,
};

const default_ability_card_detail: AbilityCardDetail = {
  info_id: 0,
  name: '',
  level: 0,
  initiative: 0,
  initiative2: 0,
  status: 0,
  enhancements: [],
  slots: [],
  stickers: [],
};

const default_items_page: ItemsPage = {
  tabs: [],
};

const default_scenario_tabs_page: ScenarioTabsPage = {
  tabs: [],
};

const default_journal_entry: JournalEntry = {
  journal_id: 0,
  journal_kind: 0,
  create_time: new Date(),
  update_time: new Date(),
  groups: [],
};

const default_settings: Settings = {
  casual: false,
  solo_variant: 0,
  difficulty: 0,
  normal: 0,
  scenario_level: 0,
  monster_level: 0,
  reveal_mode: 0,
  summons_drop_coins: false,
  map_config: 0,
  player_count: 0,
  player_ids: [],
  donated_ids: [],
  player_goals: {},
  auto_item: false,
  auto_map: false,
  auto_condition: false,
  auto_ability: false,
  enhance_game_id: 0,
  unlimited_market: false,
  pool_resources: false,
};

const default_book_info: BookInfo = {
  info_id: 0,
  info_kind: 0,
  game_id: 0,
  name: '',
  object_code: '',
  lock_kind: 0,
  book_kind: 0,
  base_path: '',
  first_page: 0,
  last_page: 0,
  page_width: 1,
  page_height: 1,
};

const default_rect: Rect = { x: 0, y: 0, width: 1, height: 1 };

const default_label_rect: LabelRect = { ...default_rect, label: '' };

const default_scenario_page_info: ScenarioPageInfo = {
  book_id: 0,
  page_number: 0,
  crop: default_label_rect,
  hide: [],
};

const default_browse_mob_detail: BrowseMobDetail = {
  game_id: 1,
  info_id: 0,
  portrait_layout: 0,
  monster_deck_id: 0,
  deck_name: '',
  name: '',
  levels: [],
  next_id: 0,
  prev_id: 0,
};

const default_browse_simple_detail: BrowseSimpleDetail = {
  game_id: 1,
  info_id: 0,
  info_kind: 0,
  kind: '',
  portrait_layout: 0,
  name: '',
  abilities: [],
  next_id: 0,
  prev_id: 0,
};

const default_browse_scenario_detail: BrowseScenarioDetail = {
  game_id: 1,
  info_id: 0,
  code: '',
  name: '',
  special_rules: [],
  next_id: 0,
  prev_id: 0,
};

const default_browse_ability_card_detail: BrowseAbilityCardDetail = {
  info_id: 0,
  name: '',
  level: 0,
  initiative: 0,
  slots: [],
  actions: [],
  next_id: 0,
  prev_id: 0,
};

export const default_page_data: RenderPageData = {
  browse_monster: default_browse_mob_detail,
  browse_boss: default_browse_mob_detail,
  browse_simple: default_browse_simple_detail,
  browse_scenario: default_browse_scenario_detail,
  browse_ability_card: default_browse_ability_card_detail,
  campaign: {
    name: '',
    game_id: 0,
    scenario_id: 0,
    prosperity_level: 1,
    ro_uuid: '',
  },
  characters_layout: {
    players: [],
    supply: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    player_ids: [],
    in_progress: false,
  },
  image_detail: {
    image_version: 0,
    image_infos: [],
    image_infos_map: new Map(),
  },
  play_page: {
    players: [],
    figure_groups: {},
    figures: {},
    group_order: [],
    elements: {},
    map_state: default_map_state,
    modifier_decks: [],
    ability_decks: [],
    current_deck: '',
    scenario: default_full_scenario_detail,
    level: 0,
    trap_delta: 0,
    round: 0,
    current_figure_id: 0,
    play_state: 0,
    condition_ids: [],
    reveal_mode: 0,
    special_rules: default_special_rules_detail,
    loot_deck: default_loot_deck_detail,
    section_links: [],
    challenges: [],
    pets: [],
  },
  play3_page: {
    play3: null,
    scenarios: [],
  },
  play_logs_page: {
    logs: [],
  },
  sandbox_page: {
    sandbox_sections: [],
  },
  scenario_page: {
    ...default_scenario_tabs_page,
    scenarios: [],
  },
  solo_scenario_page: {
    ...default_scenario_tabs_page,
    scenarios: [],
  },
  scenario_detail_page: default_full_scenario_detail,
  prosperity_page: {
    level: 1,
    checkmarks: 0,
    levels: [],
  },
  global_achievements: [],
  party_achievements: [],
  special_condition_details: [],
  party_goals_page: {
    party_goals: [],
    completed: 0,
    rewards: [],
  },
  sanctuary_page: {
    donated_gold: 0,
    donate_details: [],
    party: [],
    donated_ids: [],
  },
  town_records_page: {
    details: [],
    requirements: '',
    advance: '',
  },
  characters_page: {
    players: [],
  },
  retired_page: {
    players: [],
  },
  character_page: {
    ...default_player_detail,
    player_id: 0,
    status: '',
    notes: '',
    quest_progress: '',
    health: '',
    hand_size: 0,
    lineage: '',
    class_retire: [],
    items: [],
    ability_cards: [],
    perk_checkmarks: 0,
    bonus_perks: 0,
    available_perks: 0,
    selected_perks: [],
    perks: [],
    modifier_images: [],
    quest_id: 0,
    quest_details: [],
    available_quests: [],
    inspiration_quest_id: 0,
    inspiration_quests: [],
    trial_details: [],
    completed_trials: null,
    retired_trials: null,
    milestone: default_milestone_detail,
    masteries: [],
    has_crossover: false,
    resources: [],
    inspiration: 0,
  },
  character_ability_page: {
    info_id: 0,
    class_id: 0,
    top_abilities: [],
    bottom_abilities: [],
    user_top_abilities: [],
    user_bottom_abilities: [],
    stickers: [],
    cards: [],
  },
  enhance_page: {
    enhance_game_id: 0,
    player: default_player_detail,
    class_id: 0,
    detail: default_ability_card_detail,
    slots: [],
    enhanced_cards: [],
    discounts: [0, 0, 0],
  },
  new_character_page: {
    classes: [],
    level: 0,
    start_group: '',
    lineages: [],
    crimson_scales: false,
    selected_class: null,
  },
  lineage_page: {
    lineages: [],
  },
  shop_items_page: {
    ...default_items_page,
    items: [],
    players: [],
    cost_modifier: 0,
    show_flip: false,
    unhealthy_herbs: [],
    supply: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    pool_resources: false,
  },
  unlock_items_page: {
    items: [],
  },
  unlock_quests_page: {
    quests: [],
    fix_quests: false,
  },
  unlock_goals_page: {
    goals: [],
    in_deck: [],
    players: [],
  },
  journal_page: {
    entries: [],
  },
  event_page: {
    info_kind: 0,
    event_kind: '',
    journal_id: 0,
    events: [],
    season: 'summer',
  },
  event_deck_page: {
    decks: [],
    info_kind: 0,
    event_kind: '',
    events: [],
  },
  random_scenarios_page: {
    ...default_scenario_tabs_page,
    scenarios: [],
    remaining: 0,
  },
  random_items_page: {
    ...default_items_page,
    decks: [],
  },
  battle_goals_page: {
    goals: [],
    players: [],
    back: default_fs_image,
  },
  challenges_page: {
    unlocked: false,
    drawn: 0,
    challenges: [],
  },
  pets_page: {
    unlocked: false,
    pets: [],
  },
  favors_page: {
    unlocked: false,
    favors: null,
  },
  world_map_page: {
    map: null,
    stickers: [],
    in_progress: false,
  },
  difficulty_page: {
    normal: 0,
    difficulty: 0,
    map_config: 0,
    solo_variant: 0,
  },
  attack_modifiers_page: {
    players: [],
    figure_groups: {},
    figures: {},
    effects: '',
    event_effects: [],
    condition_ids: [],
  },
  choose_scenario_page: {
    ...default_scenario_tabs_page,
    detail: default_full_scenario_detail,
    scenarios: [],
    round: 0,
    play_state: 0,
    enabled_scenarios: [],
  },
  treasure_index_page: {
    treasures: [],
  },
  add_monsters_page: {
    infos: [],
    added_ids: [],
  },
  end_scenario_page: {
    param: 0,
    scenario_id: 0,
    game_id: 1,
    journal: default_journal_entry,
    success: false,
    level: 0,
    unlooted_coins: 0,
    players: [],
    can_replay: false,
    trap_delta: 0,
  },
  unlock_classes_page: {
    classes: [],
  },
  settings_page: {
    settings: default_settings,
    condition_ids: [],
    players: [],
    games: [],
  },
  help_page: {
    feedback: [],
  },
  books: [],
  scenario_pages_page: {
    detail: default_scenario_detail,
    sections: [],
    pages: [],
    books: [],
    revealed: [],
    hidden: [],
    campaign_id: 0,
    map: [],
    forteller_href: null,
    forteller: null,
  },
  section_page: {
    section_id: 0,
    journal_id: 0,
    book: default_book_info,
    page: 0,
    rect: default_rect,
    label: '',
    resolved: false,
    editing: false,
    actions: [],
    forteller_href: null,
  },
  edit_page_sections_page: {
    books: [],
    sections: [],
    can_edit: false,
  },
  edit_scenario_page: {
    detail: default_scenario_detail,
    scenario_sections: [],
    pages: [],
    books: [],
    can_edit: false,
    record: null,
    monsters: [],
    enemies: [],
    monster_decks: [],
    tiles: [],
    map: [],
    scenarios: [],
    sections: [],
    prev_page: default_scenario_page_info,
    section_page_map: {},
  },
  edit_info_page: {
    can_edit: false,
    info: null,
    record: null,
    next_info_id: 0,
    prev_info_id: 0,
    next_class_id: 0,
    prev_class_id: 0,
  },
  campaign_sheet_page: {
    resource_count: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    morale: 0,
    morale_actions: [],
    soldier_count: 0,
    max_soldiers: 0,
    inspiration: 0,
    total_defense: 0,
    season: 'summer',
    weeks_passed: 0,
    calendar: [],
    looted_item_scenarios: [],
    buildings: [],
    party_debt: zero_fh_cost(),
    party_gain: zero_fh_cost(),
    unhealthy_herbs: [],
    loot_cards: [],
    town_guard_deck: null,
    town_guard_perks: null,
    town_guard_non_perks: null,
    perks: [],
    prosperity_level: 0,
    prosperity_checkmarks: 0,
    prosperity_levels: [],
    campaign_stickers: [],
  },
  unlock_buildings_page: {
    buildings: [],
  },
  buildings_page: {
    buildings: [],
    party_debt: zero_fh_cost(),
    party_gain: zero_fh_cost(),
    total: zero_fh_cost(),
    supply: [],
    inspiration: 0,
    morale: 0,
    crops: [],
    carpenter: 0,
    donated_ids: [],
  },
  loot_cards_page: {
    cards: [],
    actions_1418: [],
    actions_1419: [],
  },
};

export function get_card_status(card: PlayCardDetail, player?: PlayPlayerDetail) {
  if (player && player.random_id === card.info_id) return AbilityCardStatus.RANDOM;
  if (card.card_status === AbilityCardStatus.SELECT && card.action_index >= 0) {
    if (card.action_index >= card.actions.length) return AbilityCardStatus.DISCARD;
    return card.actions[card.action_index].card_status;
  }
  return card.card_status;
}

export function get_active_final(card: PlayCardDetail) {
  if (card.card_status === AbilityCardStatus.SELECT && card.action_index >= 0) {
    if (card.action_index >= card.actions.length) return AbilityCardStatus.NONE;
    return card.actions[card.action_index].active_final;
  }
  return card.active_final;
}
