import type { Hex } from './hex.js';
import type { FsImage } from './fs_image.js';
import type { MapItemKind } from './info.js';
import { PlayState } from './gloomhaven.js';

export enum ComponentKind {
  NONE = 0,
  CURRENT_TRANSACTION_ID = 1,
  NEXT_TRANSACTION_ID = 2,
  UNDO_TRANSACTION_ID = 3,
  SCENARIO = 4,
  ROUND = 5,
  ELEMENTS = 6,
  REVEALED = 7,
  IMAGE_INFO = 8,
  MAP_POSITION = 9,
  FIGURE_INFO = 10,
  TRAP_MISC = 1000, // TODO: add the actual components
}

export interface Component {
  // Assuming we have a lot of components in a hash map, save space by
  // not including the kind in the component.
  // component_kind: ComponentKind;
}

export interface EntityId extends Component {
  entity_id: number;
}

export interface TransactionId extends Component {
  transaction_id: number;
}

export interface Scenario extends Component {
  campaign_name: string;
  game_id: number;
  scenario_id: number;
  name: string;
  icon?: string;
  player_count: number;
  difficulty: number;
  scenario_level: number;
  monster_level: number;
  gold_per_token: number;
  bonus_xp: number;
  trap_damage: number;
  hazard_damage: number;
}

export interface Round extends Component {
  play_state: PlayState;
  round: number;
}

export interface Elements extends Component {
  fire: number; // enum ElementState
  ice: number;
  air: number;
  earth: number;
  light: number;
  dark: number;
}

export interface Revealed extends Component {
  revealed: string[];
}

export interface MapRender extends Component {
  q: number;
  r: number;
  hex_rotate: number;
}

export interface ImageInfo extends Component {
  info_id: number;
  game_id: number;
  map_item_kind: MapItemKind;
  path: string;
  size: number;
  center_x: number;
  center_y: number;
  rotate: number;
  hexes: Hex[];
}

export interface MapPosition extends Component {
  q: number;
  r: number;
  hex_rotate: number;
}

export interface FigureInfo extends Component {
  info_id: number;
  game_id: number;
  portrait: FsImage;
}

export interface TrapMisc extends Component {
  misc: string[];
}
