export interface HashMap<T> {
  [key: string]: T;
}

export interface NumberMap<T> {
  [key: number]: T;
}

export interface PlayerId {
  player_id: number;
}

export function capitalize_first(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function capitalize(str: string): string {
  return str
    .replace(/[-_]/g, ' ')
    .replace(/\w\S*/g, (w) => w.replace(/^\w/, (c) => c.toUpperCase()))
    .replace(/ Of /g, ' of ')
    .replace(/ The /g, ' the ')
    .replace(/ In /g, ' in ')
    .replace(/ On /g, ' on ')
    .replace(/ A /g, ' a ')
    .replace(/ An /g, ' an ')
    .replace(/ As /g, ' as ')
    .replace(/ S /g, ' s ')
    .replace(/ And /g, ' and ')
    .replace(/ To /g, ' to ')
    .replace(/ For /g, ' for ')
    .replace(/ By /g, ' by ');
}

export function minElem(arr: number[], def: number) {
  if (!arr || !arr.length) return def;
  return arr.reduce((acc, val) => Math.min(acc, val), def);
}

export function maxElem(arr: number[], def: number) {
  if (!arr || !arr.length) return def;
  return arr.reduce((acc, val) => Math.max(acc, val), def);
}

export function lastElem<T>(arr: T[]) {
  if (!arr || !arr.length) return null;
  return arr[arr.length - 1];
}

export function range(count: number) {
  return [...Array(count).keys()];
}

export function shuffle(deck: any[], first?: number) {
  const length = deck.length;
  for (let i = first ?? 0; i < length - 1; i++) {
    var r = Math.floor(Math.random() * (length - i)) + i;
    var t = deck[i];
    deck[i] = deck[r];
    deck[r] = t;
  }
  return deck;
}

export function expr_str(expr: string) {
  if (!expr) return '??';
  let stack: any[] = [''];
  let in_string: boolean = false;
  for (let c of expr) {
    if (in_string && c !== '}') {
      stack.push(stack.pop() + c);
      continue;
    }
    switch (c) {
      case '.':
        stack.push('');
        break;
      case '0':
        stack.push(stack.pop() + '0');
        break;
      case '1':
        stack.push(stack.pop() + '1');
        break;
      case '2':
        stack.push(stack.pop() + '2');
        break;
      case '3':
        stack.push(stack.pop() + '3');
        break;
      case '4':
        stack.push(stack.pop() + '4');
        break;
      case '5':
        stack.push(stack.pop() + '5');
        break;
      case '6':
        stack.push(stack.pop() + '6');
        break;
      case '7':
        stack.push(stack.pop() + '7');
        break;
      case '8':
        stack.push(stack.pop() + '8');
        break;
      case '9':
        stack.push(stack.pop() + '9');
        break;
      case '@':
        stack.push(`abs(${stack.pop()})`);
        break;
      case '*':
        var right = stack.pop();
        var left = stack.pop();
        stack.push(`(${left}*${right})`);
        break;
      case '/':
        var right = stack.pop();
        var left = stack.pop();
        stack.push(`floor(${left}/${right})`);
        break;
      case '^':
        var right = stack.pop();
        var left = stack.pop();
        stack.push(`ceil(${left}/${right})`);
        break;
      case '%':
        var right = stack.pop();
        var left = stack.pop();
        stack.push(`(${left}%${right})`);
        break;
      case '+':
        var right = stack.pop();
        var left = stack.pop();
        stack.push(`(${left}+${right})`);
        break;
      case '-':
        var right = stack.pop();
        var left = stack.pop();
        stack.push(`(${left}-${right})`);
        break;
      case '&':
        stack.push(`(${stack.pop()}&${stack.pop()})`);
        break;
      case '|':
        stack.push(`(${stack.pop()}|${stack.pop()})`);
        break;
      case '<':
        var right = stack.pop();
        var left = stack.pop();
        stack.push(`(${left}<${right})`);
        break;
      case '>':
        var right = stack.pop();
        var left = stack.pop();
        stack.push(`(${left}>${right})`);
        break;
      case '=':
        var right = stack.pop();
        var left = stack.pop();
        stack.push(`(${left}=${right})`);
        break;
      case '!':
        stack.push(stack.pop() ? 0 : 1);
        stack.push(`(!${stack.pop})`);
        break;
      case '{':
        stack.push('"');
        in_string = true;
        break;
      case '}':
        stack.push(stack.pop() + '"');
        in_string = false;
        break;
      case '#':
        // TODO: don't know the number of args to pop
        apply_func_str(stack);
        break;
      case '$':
        // lookup name in scope
        stack.push('$' + stack.pop());
        break;
      default:
        if (c >= 'A' && c <= 'Z') {
          stack.push(c);
        } else {
          console.log(`unknown character: ${c}`);
          console.log(stack);
        }
        break;
    }
  }
  return stack.reverse().join(',');
}

function apply_func_str(stack: any[]) {
  const name: string = stack.pop();
  var arg1: any;
  var arg2: any;
  switch (name) {
    case 'is_alive':
      arg1 = stack.pop();
      arg2 = stack.pop();
      stack.push(`${name}(${arg1},${arg2})`);
      break;
    case 'is_revealed':
    case 'count_token':
    case 'count_figure':
      arg1 = stack.pop();
      stack.push(`${name}(${arg1})`);
      break;
    default:
      console.log(`unknown function: ${name}`);
      console.log(stack);
      break;
  }
}

export function string_to_hex(str: string): string {
  return bytes_to_hex(new TextEncoder().encode(str));
}

export function hex_to_string(hex: string): string {
  return new TextDecoder().decode(hex_to_bytes(hex));
}

function bytes_to_hex(bytes: Uint8Array): string {
  return Array.from(bytes, (byte) => byte.toString(16).padStart(2, '0')).join('');
}

function hex_to_bytes(hex: string): Uint8Array {
  const bytes = new Uint8Array(hex.length / 2);
  for (let i = 0; i !== bytes.length; i++) {
    bytes[i] = parseInt(hex.substr(i * 2, 2), 16);
  }
  return bytes;
}

export function objectToBase64(obj: any): string {
  return bytesToBase64(JSON.stringify(obj));
}

export function base64ToObject(base64: string): any {
  return JSON.parse(base64ToBytes(base64));
}

export function base64ToBytes(base64: string): string {
  const binString = atob(base64);
  const bytes = Uint8Array.from(binString, (m) => m.codePointAt(0));
  return new TextDecoder().decode(bytes);
}

export function bytesToBase64(str: string): string {
  const bytes = new TextEncoder().encode(str);
  const binString = Array.from(bytes, (x) => String.fromCodePoint(x)).join('');
  return btoa(binString);
}

export function urlBase64ToUint8Array(base64String: string) {
  var padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  var base64 = (base64String + padding).replace(/\-/g, '+').replace(/_/g, '/');
  var rawData = window.atob(base64);
  var outputArray = new Uint8Array(rawData.length);
  for (var i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

export function abbr(name: string) {
  return name
    .split(' ')
    .map((n) => n.substr(0, 4))
    .filter((n) => !n.startsWith('#'))
    .join('');
}

export function deep_freeze(obj: any) {
  Object.keys(obj).forEach((prop) => {
    if (typeof obj[prop] === 'object' && !Object.isFrozen(obj[prop]))
      deep_freeze(obj[prop]);
  });
  return Object.freeze(obj);
}

export function object_equal(a: any, b: any): boolean {
  if (a == null && b == null) return true;
  if (a == null || b == null) return false;
  const kind_a = Object.prototype.toString.call(a);
  const kind_b = Object.prototype.toString.call(b);
  if (kind_a !== kind_b) return false;
  if (kind_a === '[object Array]') {
    if (a.length !== b.length) return false;
    return a.every((_: any, i: number) => object_equal(a[i], b[i]));
  }
  if (kind_a === '[object Object]') {
    return Array.from(new Set(Object.keys(a).concat(Object.keys(b)))).every((key: any) =>
      object_equal(a[key], b[key])
    );
  }
  // case '[object Date]':
  // case '[object String]':
  // case '[object Number]':
  // case '[object Boolean]':
  // case '[object Null]':
  // case '[object Undefined]':
  // case '[object Function]':
  // case '[object RegExp]':
  return a === b;
}
